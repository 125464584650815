const { vsCurrentStyle, vsCurrentView } = window;
const THREAD_POSITIONS = ["BTF", "In-Thread"];

function loadBrowsiAdData() {
  const NUMBER_OF_ADS = 10;
  const AD_TEMPLATE = {
    AdSlot: "",
    NativeStyle: vsCurrentStyle,
    NativeView: vsCurrentView,
    Position: THREAD_POSITIONS,
    folder1: "",
    prebidTech: "BR",
  };
  const adDataArray = [];
  const adDataArrayMobile = [];

  for (let i = 0; i < NUMBER_OF_ADS; i++) {
    const BROWSI_VALUE_PREFIX = "browsi_";
    const desktopAd = { ...AD_TEMPLATE };
    desktopAd.AdSlot = "In-Thread_Lower";
    desktopAd.folder1 = BROWSI_VALUE_PREFIX + i;
    adDataArray.push(desktopAd);

    const mobileAd = { ...AD_TEMPLATE };
    mobileAd.AdSlot = "In-Thread_Lower_Mobile";
    mobileAd.folder1 = `${BROWSI_VALUE_PREFIX}mobile_${i}`;
    adDataArrayMobile.push(mobileAd);
  }

  return adDataArray.concat(adDataArrayMobile);
}

window.browsiKeyValues = loadBrowsiAdData();
